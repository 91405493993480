.h1-responsive {
    font-size: 8rem !important;
  }
  
  @media (max-width: 768px) {
    .h1-responsive {
      font-size: 4rem !important;/* Adjust based on your needs */
    }
  }

.contact-responsive {
    font-size: 1.5rem !important;
}

@media (max-width: 768px) {
    .contact-responsive {
      font-size: 1rem !important; /* Adjust based on your needs */
    }
  }