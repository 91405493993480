.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

@media (max-width: 1280px) {
    .card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1px;
    }
  }

.card-content{
    height: 50%;
    width: 40%;
    padding-bottom: 50px;
}

@media (max-width: 1280px) {
    .card-content {
        width: 60%;
        height: 100%;
    }
  }

  @media (max-width: 768px) {
    .card-content {
        width: 100%;
        height: 100%;
    }
  }

@media (max-width: 768px) {
    .about-title {
        padding-top: 40%;
    }
  }

@media (max-width: 768px) {
    .about-text {
        padding-bottom: 20%;
    }
  }